.navbar {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav-ul {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
}

.nav-ul li {
    flex: 1;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropWrap {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.navbar li:hover {
    background-color: #585858;
}

.navbar a {
    color: #FFFFFF;
    letter-spacing: 0.125px;
}

.dropdown-content {
    width: 100%;
    height: clamp(112.5px, 10.5vw, 200px);  /*  navbar height *2.5 */
    margin-top: clamp(158px, 14.5vw, 280px);
    padding: 0;
    opacity: 0;
    position: absolute;
    background-color: #585858;

    display: flex;
    flex-direction: column;
    list-style: none;
    z-index: 1;
}

.dropdown-content li {
    display: flex;
    text-align: center;
}

.dropdown-content a {
    color: #FFFFFF;
    font-size: clamp(0.7rem, 1.1vw, 1.25rem);
}

.dropdown-content li:hover {
    background-color: #F5F2ED;
}

.dropdown-content li:hover a.dropItem {
    color: #585858;
}


.dropdown:hover .dropdown-content {
    opacity: 1;
    transition-duration: 500ms;
}

@media only screen and (max-width: 900px) {
    .navbar {
        width: 100%;
    }

    .nav-ul {
        flex-direction: column;
    }

    .nav-ul li {
        padding: 20px;
    }

    .dropdown-content {
        display: none;
    }

    .navbar a {
        font-size: 1.7rem;
    }
}

