/* General Content containers  */
.cntContainer {
    width: 100vw;
    margin: 4vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cntWrap {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 900px) {
    .cntWrap {
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .cntWrap {
        width: 90%;
    }
}