.header {
    width: 100vw;
    height: clamp(45px, 4.2vw, 80px);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: #6A6A6A;
}

.nav_container {
    width: 83%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.nav_logo {
    display: flex;
    align-items: center;
}

.nav_logo a {
    margin-left: 1vw;
    color: #FFFFFF;
    flex-grow: 4;
    align-self: center;
}

.nav_logo img {
    height: clamp(28px, 2.6vw, 50px);
    aspect-ratio: 1;
    align-self: center;
}