.hamburger-nav {
    height: 100%;
    display: flex;
    align-items: center;
}

/* Expand to full view after clicked */
.open .hamburger-nav {
    width: 100vw;
}

/* Wrap the hamburger icon so that the entire area could be clicked */
.hamburger-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* The hamburger menu icon */
.hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: -20px;
    width: 25px;
    height: 8px;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: transform 0.2s ease;
}

.line-1 {
    transform: translateY(0px);
}

.line-2 {
    transform: translateY(8px);
}

.line-3 {
    transform: translateY(16px);
}

/* Reshape into X shape after clicked */
.open .line-1 {
    transform: translateY(8px) rotate(-45deg);
}

.open .line-2 {
    opacity: 0;
}

.open .line-3 {
    transform: translateY(3px) rotate(45deg);
}

/* Dropdown menu after hamburger clicked */
.menu {
    width: 100vw;
    background-color: #6A6A6A;
    position: absolute;
    top: clamp(45px, 4.2vw, 80px);;
    left: 0;
    display: none;
    opacity: 0;
    flex-direction: column;
    text-align: center;
    z-index: 3;
}

.menu ul {
    list-style-type: none;
}

.open .menu {
    animation-name: swipeIn;
    animation-duration: 1s;
    display: flex;
    opacity: 1;
}



/* Swipe in animation for menu block */
@keyframes swipeIn {
    0% {
        transform: translateY(-200px);
    }
    100% {
        transform: translateY(0px);
    }
}
