.container {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin: clamp(16px,2vw,36px) 0;
}

.cardWraps {
    width: 70%;
    padding: 3% 4%;
    background-color: #F5F2ED;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
}

/* Responsive for screen size under 900px and 600px */
@media only screen and (max-width: 900px) {

}

@media only screen and (max-width: 600px) {
    .cardWraps {
        row-gap: 0;
    }
}