.tool-card {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.tool-card-title {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 1vw;
    align-items: center;
}

.tool-card-title img {
    width: 2.5vw;
}

.tool-card-title h3 {
    font-size: 4vw;
    font-weight: normal;
}

.tool-card-title h3::after {
    content: '';
    width: 4vw;
    height: clamp(1px, 0.2vw, 3px);
    background-color: #000000;
    position: absolute;
    margin: 4vw -4vw;
}


.tool-card-title .tool-title {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: clamp(1.125rem, 2vw, 2.25rem);
    font-weight: bold;
}

.tool-card .tool-info {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: clamp(1rem, 1.6vw, 1.75rem);
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .tool-card {
        width: 100%;
    }
}