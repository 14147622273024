.container {
    width: 100vw;
    display: inline-flex;
    justify-content: center;
}

.mainImage {
    width: 100%;
    height: auto;
}

.title {
    width: 100%;
    height: auto;
    position: absolute;
    /*margin-top: 12%;*/
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 1;
}

.titleLeft {
    width: 80%;
    justify-content: flex-start;
}

.ttlContainer {
    width: 50%;
    height: auto;
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.ttlContainer .mainTitle {
    width: 100%;
    line-height: 1rem;
    font-weight: normal;
}

.title .subTitle {
    width: 100%;
    color: #303030;
    font-family: 'Abhaya Libre', serif;
    font-size: clamp(1rem, 1.6vw, 1.75rem);
}

/* Responsive for screen size under 900px and 600px */
@media only screen and (max-width: 900px) {
    /* Cancel clipping of main image */
    .mainImage {
        clip-path: none;
    }
}

@media only screen and (max-width: 600px) {
    /* width 100% to make texts aligned center */
    .ttlContainer {
        width: 100%;
        margin-top: 5%;
        align-items: center;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .title {
        position: relative;
    }

    /* Titles align center */
    .mainTitle {
        text-align: center;
    }

    .subTitle {
        text-align: center;
    }
}