.App {
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
}

h2 {
    color: #000000;
    font-family: 'Abhaya Libre', serif;
    font-size: clamp(1.7rem, 2.7vw, 3rem);
    font-weight: bold;
    letter-spacing: 0.2rem;
}

h3 {
    margin: 0;
    padding: 0;
    color: #000000;
    font-family: 'Abhaya Libre', serif;
    font-size: clamp(1.125rem, 2vw, 2.25rem);
}

a {
    color: #000000;
    font-family: 'Abhaya Libre', serif;
    font-size: clamp(1rem, 1.6vw, 1.75rem);
    text-decoration: none;
}

p {
    color: #000000;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: clamp(0.5rem, 0.8vw, 1rem);
}