.wrap {
    width: 100vw;
    padding-bottom: 10vw;
    position: relative;
    display: flex;
    justify-content: center;
}

.info-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.info-background {
    width: 77vw;
    height: 33vw;
    background-color: #F5F2ED;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.info-container h2 {
    margin: 8vw 0;
}

.info-li-container {
    width: 90vw;
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 900px) {
    .info-container {
        width: 88%;
        background-color: #F5F2ED;
    }

    .info-container h2 {
        margin-bottom: 0;
    }

    .info-li-container {
        width: 88%;
        padding: 6%;
        background-color: #F5F2ED;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 40px;
    }

    .info-background {
        display: none;
    }

}