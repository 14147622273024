.container {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin: clamp(5px,1.2vw,20px) 0;
}

.cardWraps {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

/* Responsive for screen size under 900px and 600px */
@media only screen and (max-width: 900px) {

}

@media only screen and (max-width: 600px) {
    .cardWraps {
        flex-direction: column;
        justify-content: center;
    }
}