.container {
    width: 100vw;
    height: clamp(522px, 55vw, 960px);
    display: inline-flex;
    justify-content: center;
}

.background {
    width: 80%;
    height: 96%;
    background-color: #F5F2ED;
    display: inline-flex;
    justify-content: center;
}

.abt-container {
    align-self: center;
    display: grid;
    grid-template-columns: 17vw 3vw 3vw 27vw;
    grid-template-rows: 2.7vw 11.5vw 22vw 9vw;
}

.abt-selfie {
    width: 26vw;
    height: 31vw;
    grid-column-start: 1;
    grid-row-start: 3;
}

.abt-selfie img {
    width: 100%;
    height: 100%;
}

.abt-title {
    width: fit-content;
    height: fit-content;
    padding: 1.3vw 2.2vw;
    border: 0.1rem solid #000000;
    margin-top: 2vw;
    grid-column-start: 2;
    grid-row-start: 2;
    z-index: 2;
}

.abt-title h3 {
    width: clamp(120px, 14vw, 240px);
}

.abt-info {
    width: 27vw;
    height: 29vw;
    background-color: #FFFFFF;
    text-align: justify;
    grid-column-start: 4;
    grid-row-start: 2;
    display: flex;
    justify-content: center;
}

.abt-info p {
    width: 70%;
    margin-top: 4.8vw;
    align-self: center;
}

.abt-but {
    width: 10vw;
    height: 2.6vw;
    grid-column-start: 3;
    grid-row-start: 4;
}

.abt-but button {
    width: 100%;
    height: 100%;
    background-color: #323232;
    border: none;
    color: #FFFFFF;
    font-family: 'Abhaya Libre', serif;
    font-size: 1.4vw;
}

.abt-line {
    display: flex;
    grid-column-start: 3;
    grid-row-start: 3;
    margin-top: -1vw;
}

.abt-line span {
    content: '';
    width: clamp(1px, 0.2vw, 3px);
    height: 22vw;
    background-color: #000000;
    z-index: 2;
}

@media only screen and (max-width: 900px) {
    .container {
        width: 100vw;
        height: fit-content;
    }

    .background {
        width: fit-content;
        height: fit-content;
        padding: 6% 10%;
    }

    .abt-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .abt-selfie {
        width: 60vw;
        height: auto;
        aspect-ratio: 3/4;
    }

    .abt-title {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .abt-title h3 {
        width: fit-content;
        font-size: 2rem;
    }

    .abt-info {
        width: 60vw;
        height: fit-content;
    }

    .abt-info p {
        width: 100%;
        margin: 0;
        padding: 2%;
        font-size: 1rem;
        text-align: center;
    }

    .abt-but {
        width: 20vw;
        height: 6vw;
    }

    .abt-but button {
        font-size: 1rem;
    }

    .abt-line {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .background {
        width: 100vw;
    }
}