.ftwrk-cnt h3 {
    font-family: 'Noto Sans JP', sans-serif;
}

.ftwrk-cnt .intro {
    color: #000000;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: clamp(1rem, 1.6vw, 1.75rem);
    text-align: center;
    line-height: 2;
    margin: 2vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intro ul {
    width: 80%;
    list-style-type: circle;
}

.intro ol {
    width: 90%;
}

.intro li {
    text-align: start;
}

.ftwrk-cnt img {
    width: 100%;
}