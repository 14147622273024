.foot-list {
    width: 100%;
    height: clamp(45px, 3vw, 60px);
    display: flex;
    justify-content: center;
    align-self: center;
}

.foot-list img {
    width: 2vw;
    aspect-ratio: 1;
    margin: 1vw;
    align-self: center;
}

.foot-list a {
    color: #F5F2ED;
    letter-spacing: 3px;
    flex-grow: 4;
    align-self: center;
}

.foot-list a::after {
    content: '';
    width: 0;
    height: 1px;
    background-color: #F5F2ED;
    position: absolute;
    margin-top: clamp(10px, 2vw, 30px);
    margin-left: clamp(-80px, -5vw, -52px);
    transition: all ease-in-out 250ms;
}

.foot-list a:hover::after {
    width: clamp(50px, 5vw, 80px);
}

@media only screen and (max-width: 900px) {
    .foot-list img {
        width: 30px;
        aspect-ratio: 1;
        margin: 10px;
        align-self: center;
    }

    .foot-list a {
        font-size: 1.5rem;
    }

    .foot-list a::after {
        content: '';
        width: 0;
        height: 1px;
        background-color: #F5F2ED;
        position: absolute;
        margin-top: 30px;
        margin-left: -70px;
        transition: all ease-in-out 250ms;
    }

    .foot-list a:hover::after {
        width: 70px;
    }

}

