.sep-container {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.sep-wrap {
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1vw 0;
}

.sep-wrap p {
    font-size: clamp(0.8rem, 0.8vw, 1rem);
}

.line {
    height: clamp(1px,0.1vw,2px);
    width: 4.5vw;
}

@media only screen and (max-width: 1200px) {
    .line {
        width: 4vw;
    }
}

@media only screen and (max-width: 900px) {
    .sep-wrap {
        width: 25%;
        margin: 20px 0;
    }

    .sep-wrap p {
        margin: 0 2vw;
    }

    .line {
        width: 70%;
        height: 1px;
    }
}

@media only screen and (max-width: 600px) {
    .sep-wrap {
        width: 40%;
        margin: 20px 0;
    }

    .sep-wrap p {
        margin: 0 8px;
    }

    .line {
        width: 80%;
        height: 1px;
    }
}